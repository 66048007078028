<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full vx-card card">
        <div class="banner-block w-full">
          <!-- add section where the user gets to pick the type, and fetch data based on the type selected -->
          <h1 class="mt-10">إضافة إعلان</h1>
          <div v-if="banner.image.length">
            <img
              :src="banner.image[0].blob || banner.image[0]"
              id="preview-image"
            />
          </div>
          <div v-else>
            <div class="image-place-holder">
              <div style="text-align: center"></div>
            </div>
          </div>
          <div>
            <file-upload
              ref="upload"
              v-model="banner.image"
              v-validate="'required'"
              class="vs-button vx-col bg-primary mt-10"
              post-action="/post.method"
              put-action="/put.method"
              extensions="jpg,gif,png,webp"
              :maximum="1"
              @input-filter="inputFilter"
            >
              تنزيل صورة
            </file-upload>
          </div>

          <div class="vx-col my-3 sm:w-full w-full mb-20">
            <div class="vx-row">
              <div class="w-1/4 m-5">
                <label for="type">نوع الإعلان</label>
                <v-select
                  label="type"
                  v-validate="'required'"
                  name="type-of-banner"
                  data-vv-as="نوع الإعلان"
                  :options="banner_types"
                  v-model="select_type"
                  @input="exe(select_type)"
                  class="w-full"
                ></v-select>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('type-of-banner')"
                  >{{ errors.first("type-of-banner") }}</span
                >
              </div>
              <div
                v-if="banner_types[3] != select_type && select_type"
                class="w-1/4 m-5"
              >
                <label for="type">{{ select_type }}</label>
                <v-select
                  :label="checkType()"
                  v-validate="'required'"
                  data-vv-as="الإختيار"
                  name="selected-value"
                  :options="listSelection"
                  v-model="data_id"
                  :reduce="(item) => item.id"
                  class="w-full"
                ></v-select>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('selected-value')"
                  >{{ errors.first("selected-value") }}</span
                >
              </div>
              
            </div>
          </div>

          <div class="vx-row mt-8 btn-alignment">
            <div class="vx-col sm:w-full w-full mb-6">
              <vs-button
                class="mr-5 text-lg py-4"
                @click="$router.go(-1)"
                color="warning"
                type="border"
                >إلغاء</vs-button
              >

              <vs-button
                class="mr-5 text-lg px-16 py-4"
                @click="submit"
                color="success"
                type="filled"
                >إضافة</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import VueUploadComponent from "vue-upload-component";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      listSelection: [],
      banner_types: ["منتج",  "تجاري"],
      select_type: "",
      banner: {
        type: this.getTypeOfBanner(this.select_type),
        image: [],
        page: "",
        data_id: this.data_id,
      },
      
      data_id: "",
    };
  },

  components: {
    "file-upload": VueUploadComponent,
    vSelect,
  },
  created(){
    this.$store.dispatch("getAllProducts");
  },
  methods: {
    ...mapActions("banners", ["createBanner"]),
    // ...mapActions(""),
    getTypeOfBanner(type) {
      switch (type) {
        case "منتج":
          return "product";
        case "تجاري":
          return "not-clickable";
      }
      return type;
    },
    exe(type) {
      // this.callDataType(type);
      this.callDataType(type);
    },
    checkType() {
      switch (this.select_type) {
        case "علامة تجارية":
          return "name";
        case "منتج":
          return "sku";
        case "متجر":
          return "name";
        case "تجاري":
          return "null";
      }
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        if (this.banner.image.length <= 0) {
          this.$vs.dialog({
            title: "خطأ في إدخال البيانات",
            text: "حقل الصورة مطلوب يرجى اضافة على الاقل صورة واحدة",
            color: "danger",
            acceptText: "إغلاق",
          });
          return;
        }
        if (result) {
          var bodyFormData = new FormData();
          bodyFormData.set("type", this.getTypeOfBanner(this.select_type));
          bodyFormData.set("page", this.banner.page);
          bodyFormData.set("data_id", this.data_id ? this.data_id : "999");
          bodyFormData.set(
            "image",
            this.banner.image.length ? this.banner.image[0].file : null
          );

          this.createBanner(bodyFormData)
            .then(() => {
              this.$vs.notify({
                title: "تم بنجاح",
                text: "تمت إضافة الإعلان بنجاح",
                color: "success",
              });
              this.$router.go(-1);
            })
            .catch((error) => {
              this.errorDialog(error);
            });
        }
      });
    },
    clearSelection() {
      this.listSelection = [];
      this.data_id = "";
    },
    callDataType(type) {
      switch (type) {
        case "منتج":
          this.clearSelection();
          this.$store.dispatch("getAllProducts").then((r) => {
            this.listSelection = r.data;
          });
          return "1";
        case "تجاري":
          this.clearSelection();
          return "3";
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile.size / 1024.0 > 5000) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يجب أن لا يكون حجم الصورة أكبر من 5mb",
          color: "danger",
        });
        return prevent();
      }
      if (newFile && !oldFile) {
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يجب أن يكون الملف بإمتداد صورة صحيح",
            color: "danger",
          });
          return prevent();
        }
      }
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#preview-image {
  margin-top: 30px;
  width: 50%;
  max-height: 300px;
  border: 1px solid;
  border-color: rgb(146, 77, 43);
  padding: 2px;
  border-radius: 10px;
}
.image-place-holder {
  margin-top: 30px;
  width: 50%;
  height: 300px;
  border: 1px solid;
  padding: 2px;
  border-radius: 10px;
  border-color: rgb(146, 77, 43);
}
.btn-alignment {
  text-align: right;
}
</style>
